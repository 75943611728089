import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { ActionButton, Banner, Footer, Layout, Navbar, Schedule, Slider, SponsorLink, SponsorRow } from '@components';
import { defaultYear, siteUnlockTime } from '@variables';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '@hooks';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import '@scss/index-page.scss';

const IndexPage = ({ data }) => {
  const site = useSiteMetadata();
  const { sponsors } = data.postgres;
  const currentSponsors = sponsors.filter(sponsor => sponsor.year === defaultYear);
  const shouldShowSite = () => dayjs().isAfter(siteUnlockTime.wholeSite);

  const [ showSite, setShowSite ] = useState(shouldShowSite());

  useEffect(() => {
    const interval = setInterval(() => {
      setShowSite(shouldShowSite());

      if (showSite) {
        clearInterval(interval);
      }
    }, 1000);

    return function cleanup() {
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sponsorImageDivs = currentSponsors
    .filter(sponsor => sponsor.level === `Silver Sponsor`)
    .map(sponsor => <React.Fragment key={sponsor.id}>
      <p className="w-100 text-center align-self-start">{sponsor.level}</p>
      <div className={cn(`img`, { active: showSite })}>
        <SponsorLink sponsor={sponsor} enableLinks={showSite} />
      </div>
    </React.Fragment>);

  return <Layout className="home-page">
    <Helmet>
      <title>{site.title}</title>
    </Helmet>
    <Navbar showNavigation={showSite} />
    <Banner countdown={siteUnlockTime.openingSession} openingSessionOver={showSite} />
    <section className="testimonials-section">
      <Container>
        <Slider
          items={[
            <div className="img" key={1}>
              <StaticImage
                src="../assets/images/testimonials/Testimonial1.png"
                // eslint-disable-next-line max-len
                alt={`Testimonial: "IT Expo was an impactful experience! You see where the industry is going through the projects that are showcased."`}
              />
            </div>,
            <div className="img" key={2}>
              <StaticImage
                src="../assets/images/testimonials/Testimonial2.png"
                // eslint-disable-next-line max-len
                alt={`Testimonial: "As opportunity to engage with professionals and researchers who are leading innovation in the industry!"`}
              />
            </div>,
            <div className="img" key={3}>
              <StaticImage
                src="../assets/images/testimonials/Testimonial3.png"
                // eslint-disable-next-line max-len
                alt={`Testimonial: "A place that brings people together to build a great network and an opportunity to understand the world IT."`}
              />
            </div>,
            <div className="img" key={4}>
              <StaticImage
                src="../assets/images/testimonials/Testimonial4.png"
                // eslint-disable-next-line max-len
                alt={`Testimonial: "The IT Expo has provided me with valuable insight into the Information Technology industry!"`}
              />
            </div>,
          ]}
          options={{
            autoplaySpeed: 5000,
            dots: true,
            fade: true,
            responsive: [],
            slidesToShow: 1,
          }}
        />
      </Container>
      <div className="title text-center">
        <h2>Share Your Experience #ITEXPO{defaultYear.substring(2)} on Social Media</h2>
      </div>
      { showSite &&
        <Row className="justify-content-center">
          {/* Temporary removal, isn't ready for 2022
          <a href="https://itsc-flipbook.cech.uc.edu/soit/annualreport-20-21" target="_blank" rel="noopener noreferrer">
            <ActionButton>2020/21 Annual Report</ActionButton>
          </a> */}
          <a
            href="https://uceducation.az1.qualtrics.com/jfe/form/SV_eCJPcFn0DNLDHJY"
            target="_blank"
            rel="noopener noreferrer">
            <ActionButton>Take our Survey</ActionButton>
          </a>
        </Row>}
    </section>
    <section className="sharing-section" />
    <Schedule />
    <section className="supporters-section">
      <Container>
        <div className="title text-center">
          <h2>Thank You to Our Sponsors</h2>
          <hr className="sponsors-hr" />
        </div>
        <Row className="justify-content-center">
          <SponsorRow
            sponsors={currentSponsors.filter(sponsor => sponsor.level === `Premier Sponsor`)}
            enableLinks={showSite}
          />
        </Row>
        <Row className="justify-content-center">
          <SponsorRow
            sponsors={currentSponsors.filter(sponsor => sponsor.level === `Gold Sponsor`)}
            enableLinks={showSite}
          />
        </Row>
        <Slider
          className="supporters"
          items={sponsorImageDivs}
          options={{
            autoplaySpeed: 3000,
            responsive: [
              {
                breakpoint: 991,
                settings: {
                  slidesToScroll: 3,
                  slidesToShow: 3,
                },
              }, {
                breakpoint: 767.98,
                settings: {
                  slidesToScroll: 1,
                  slidesToShow: 2,
                },
              }, {
                breakpoint: 575.98,
                settings: {
                  slidesToScroll: 1,
                  slidesToShow: 1,
                },
              },
            ],
            slidesToShow: 3,
          }}
        />
        <Row className="justify-content-center">
          <SponsorRow
            sponsors={currentSponsors.filter(sponsor => sponsor.level === `Judging Provided By`)}
            enableLinks={showSite}
          />
        </Row>
      </Container>
    </section>
    <Footer showNavigation={showSite} />
  </Layout>;
};

export const query = graphql`
  query IndexPageQuery {
    postgres {
      sponsors: allSponsorsList {
        hasSponsorRoom
        id
        logo
        logoFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        level
        name
        year
      }
    }
  }
`;

export default IndexPage;
